import Animation from '@/components/Animation/index.vue'
import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import http from '@/services'
import FormCustomerStep1 from '../Step1/index.vue'
import FormCustomerStep2 from '../Step2/index.vue'
import FormCustomerStep3 from '../Step3/index.vue'
import httpMulti from '@/services/http'
import * as Helpers from '@/helpers'
import FormCustomerModel from '@/Models/formCustomer'
import router from '@/router'

@Component({
  components: {
    Animation,
    FormCustomerStep1,
    FormCustomerStep2,
    FormCustomerStep3,
  },
})
export default class FormCustomer extends Vue {
  @Prop({ required: false }) action!: string
  @Prop({ required: false }) form!: any
  activeStep = 0
  data: any = {}
  goForm = true
  disabled!: any
  countrySelected = ''
  isFileEditBirth = false
  isFileEditMarriage = false
  isFileEditFbi = false
  isFileEditPassport = false
  isFileEditPension = false
  isFileEditPicture = false

  Editstep1!: any
  Editstep2!: any
  Editstep3!: any

  get activeForm() {
    switch (this.activeStep) {
      case 1:
        return 'step1'
      case 2:
        return 'step2'
      case 3:
        return 'step3'
      default:
        return ''
    }
  }

  async mounted() {
    if (this.action == 'edit') {
      this.goForm = false
      let formData: any
      console.log(this.form, 'formulario')
      if (this.form) {
        this.Editstep1 = {
          ...this.form,
          date_issuance_passport: Helpers.dateParse(
            this.form.date_issuance_passport,
          ),
          date_expiry_passport: Helpers.dateParse(
            this.form.date_expiry_passport,
          ),
        }
        ;(this.Editstep2 = {
          ...this.form,
          birth_certificate: Helpers.fileParse(this.form.birth_certificate),
          marriage_certificate: Helpers.fileParse(
            this.form.marriage_certificate,
          ),
          fbi_criminal_history: Helpers.fileParse(
            this.form.fbi_criminal_history,
          ),
          passport_copies: Helpers.fileParse(this.form.passport_copies),
          pension_certificate: Helpers.fileParse(this.form.pension_certificate),
          passport_size_picture: Helpers.fileParse(
            this.form.passport_size_picture,
          ),
        }),
          (this.Editstep3 = {
            ...this.form,
            appointment_office: Helpers.dateParse(this.form.appointment_office),
          })
      }
    }
  }

  async change_step(step: number) {
    let validate = false
    let fName = ''
    fName = this.activeForm != '' ? this.activeForm : ''

    validate = await (this.$refs[fName] as any).validateFields()
    if (!validate) {
      this.activeStep = step - 1
      return
    }
    this.data[fName] = (this.$refs[fName] as any)[fName]
  }

  async saveForm() {
    try {
      await httpMulti.post(
        `api/client/client_residency/`,
        this.customerForm(this.data),
      )
      this.$buefy.toast.open({
        message: 'Se guardo correctamente',
        type: 'is-success',
      })
      this.closeForm()
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }
  async updateForm() {
    try {
      await httpMulti.put(
        `api/client/client_residency/${this.form.id}/`,
        this.customerForm(this.data),
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      this.btn_cancel()
    } catch (error: any) {
      switch (error.response.data.member_number[0]) {
        case 'Ya existe Crm Cliente Archivo con este member number.':
          this.$buefy.toast.open({
            message: `Ya exite un cliente con el mismo numero de miembro`,
            type: 'is-danger',
          })
          break
        default:
          this.$buefy.toast.open({
            message: `Ups!, algo salio mal`,
            type: 'is-danger',
          })
          break
      }
    }
  }
  customerForm(obj: any) {
    const formData = new FormData()
    const formCustomer = {
      id: obj.id,
      first_name: obj.step1.first_name,
      middle_name: obj.step1.middle_name,
      surname: obj.step1.surname,
      maiden_name: obj.step1.maiden_name,
      full_name: obj.step1.full_name,
      marital_status: obj.step1.marital_status,
      many_times_divorced: obj.step1.many_times_divorced,
      passport_number: obj.step1.passport_number,
      date_issuance_passport: Helpers.dateFormat_db(
        obj.step1.date_issuance_passport,
      ),
      date_expiry_passport: Helpers.dateFormat_db(
        obj.step1.date_expiry_passport,
      ),
      country_origin: obj.step1.country_origin,
      phone_number: obj.step1.phone_number,
      email: obj.step1.email,
      address_cr: obj.step1.address_cr,
      residency_type: obj.step2.residency_type,
      single_application: obj.step2.single_application,
      many_dependents: obj.step2.many_dependents,
      appointment_office: Helpers.dateFormat_db(obj.step3.appointment_office),
    }
    formData.append('data', JSON.stringify(formCustomer))

    if (this.isFileEditBirth) {
      formData.append('birth_certificate', obj.step2.birth_certificate)
    }
    if (this.isFileEditMarriage) {
      formData.append('marriage_certificate', obj.step2.marriage_certificate)
    }
    if (this.isFileEditFbi) {
      formData.append('fbi_criminal_history', obj.step2.fbi_criminal_history)
    }
    if (this.isFileEditPassport) {
      formData.append('passport_copies', obj.step2.passport_copies)
    }
    if (this.isFileEditPension) {
      formData.append('pension_certificate', obj.step2.pension_certificate)
    }
    if (this.isFileEditPicture) {
      formData.append('passport_size_picture', obj.step2.passport_size_picture)
    }

    return formData
  }

  birth_certificate_upload() {
    this.isFileEditBirth = true
  }
  marriage_certificate_upload() {
    this.isFileEditMarriage = true
  }
  fbi_criminal_history_upload() {
    this.isFileEditFbi = true
  }
  passport_copies_upload() {
    this.isFileEditPassport = true
  }
  pension_certificate_upload() {
    this.isFileEditPension = true
  }
  passport_size_picture_upload() {
    this.isFileEditPicture = true
  }

  closeForm() {
    this.$emit('close')
  }
  countrySelect(value: any) {
    this.countrySelected = value
  }
  btn_cancel() {
    if (this.action == 'edit') {
      this.$emit('cancelEdit')
    } else {
      this.goForm = false
    }
  }
}
