import { Component, Vue } from 'vue-property-decorator'

import Crm from '@/Models/Crm'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import http from '@/services/http'
import Search from '@/components/Search/Search.vue'
import router from '@/router'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'
import Header from '@/components/Header/Header.vue'
import Item from '@/Models/Item'
import Paginator from '@/components/Paginator/Paginator.vue'
import Animation from '@/components/Animation/index.vue'

import FormCustomer from '@/components/FormCustomer/FormCustomer/index.vue'
import Customer from '@/Models/formCustomer'

@Component({
  components: {
    FormCustomer,
    Search,
    ModalDelete,
    Header,
    Paginator,
    Animation,
  },
})
export default class ViewCrm extends Vue {
  showList = true
  data: Array<Crm> = []
  dataCustomer: Customer = new Customer()
  //accion que envia dependiendo la opcion
  action = 'add'
  url = '/api/client/client_residency/'
  params: Array<Item> = []
  loading = false
  delete_modal_active = false
  baseUrl = ''

  perPage = menuModule.perPage_param

  //th de la tabla
  header: Array<any> = [
    {
      field: 'member_number',
      label: '# miembro',
    },
    {
      field: 'client_name',
      label: 'Cliente',
    },
    {
      field: 'migratory_status',
      label: 'Estado',
    },
    {
      field: 'client_email',
      label: 'Email',
    },
    {
      field: 'client_email_alternative',
      label: 'Email alternativo',
    },
    {
      field: 'phone',
      label: 'Teléfono',
    },
    {
      field: 'file',
      label: 'Doc',
    },
  ]
  mounted() {
    this.baseUrl = process.env.VUE_APP_API_URL
    ;(this.$refs.header as any).showList = false
    ;(this.$refs.header as any).notNewBtn = true
    //menuModule.setPerpageParam(String(1))
  }

  changePerPage(range_perPage: any) {
    menuModule.setPerpageParam(String(range_perPage))
    this.perPage = menuModule.perPage_param
  }

  editObj(props: any) {
    this.dataCustomer = props
    this.showList = false
    this.action = 'edit'
    console.log(this.dataCustomer, 'formEdit')
  }
  async deleteObj() {
    try {
      await http.delete(`api/crm/crm_client_file/${this.dataCustomer.id}/`)
      this.data = this.data.filter((a: Crm) => a.id !== this.dataCustomer.id)
      this.delete_modal_active = false
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.dataCustomer = props
  }
  closeModal() {
    this.dataCustomer = new Customer()
    this.delete_modal_active = false
  }
  dependent(props: any) {
    this.dataCustomer = props
    const id = this.dataCustomer.id.toString()
    menuModule.setIdParam(id)
    this.action = 'addDependent'
    router.push({ name: 'DependentCrm' })
  }
  Observations(props: any) {
    this.dataCustomer = props
    const id = this.dataCustomer.id.toString()
    menuModule.setIdParam(id)
    this.action = 'addObservation'
    router.push({ name: 'ObservationCrm' })
  }
  PersonalDetail(props: any) {
    this.dataCustomer = props
    const id = this.dataCustomer.id.toString()
    menuModule.setIdParam(id)
    this.action = 'detail'
    router.push({ name: 'PersonalDetail' })
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    this.dataCustomer = new Customer()
    this.showList = true
    this.action = 'add'
  }
  filterItems(items: any) {
    console.log(items)
    this.data = items.map((obj: any) => ({
      ...obj,
      birth_date: Helpers.dateParse(Helpers.dateFormat(obj.birth_date)),
      date_expire_identification: Helpers.dateParse(
        obj.date_expire_identification,
      ),
      phone: Helpers.phoneFormat(obj.phone),
      house_phone: Helpers.phoneFormat(obj.house_phone),
      work_phone: Helpers.phoneFormat(obj.work_phone),
      date_approbation: Helpers.dateParse(obj.date_approbation),
      date_start_membership: Helpers.dateParse(obj.date_start_membership),
      date_expire_membership: Helpers.dateParse(obj.date_expire_membership),
      file: obj.file != null ? obj.file.slice(this.baseUrl.length) : obj.file,
      upload:
        obj.file != null
          ? new File(['foo'], obj.file.substring(obj.file.lastIndexOf('/') + 1))
          : null,
      migratory_status:
        obj.migratory_status == null ? 'INACTIVO' : obj.migratory_status,
    }))
  }
  async SearchFilter(search: string) {
    this.params = [] // se limpia el arreglo de params
    this.params.push({
      text: 'search', // nombre del parametro que se agrega a la url
      value: search, // valor del parametro
    })
    const currentPage = await (this.$refs['Paginator'] as any)['current'] //Se guarda la página actual
    await (this.$refs['Paginator'] as any).paginate(currentPage) // se ejecuta el metodo de paginar sobre la misma página actual pero ahora con los debidos parametros extras
  }
}
